import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fieldsApi from "../../api/fieldsApi";


export const getAllFields = createAsyncThunk("fields/getAllFields", async() => {
    const fieldsList = fieldsApi.getAllFields()
    return fieldsList
})

const fieldsSlice = createSlice({
    name: "fields",
    initialState: {
        loading: false,
        fieldsList: []
    },
    extraReducers: {
        [getAllFields.pending]: (state, action) => {
            state.loading = true
        },
        [getAllFields.rejected]: (state, action) => {
            state.loading = false
        },
        [getAllFields.fulfilled]: (state, action) => {
            // console.log(action.payload.data);
            state.loading = false
            state.fieldsList = action.payload
        }
    }

})

export default fieldsSlice