import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import eventsApi from "../../api/eventsApi";

export const getAllEvent = createAsyncThunk("events/getAllEvent", async () => {
	const res = await eventsApi.getAll();
	console.log("allEvents: ", res);
	return res;
});
export const getAllEventWithPag = createAsyncThunk("getAllEventWithPag/event", async ({ index, size }) => {
	const res = await eventsApi.getAllwithPag(index, size);
	return res;
});

const eventSlice = createSlice({
	name: "events",
	initialState: {
		eventList: [],
		eventListPag: [],
		loadingEvent: false,
		error: "",
	},
	reducers: {},
	extraReducers: {
		[getAllEvent.pending]: (state, action) => {
			state.loadingEvent = true;
		},
		[getAllEvent.rejected]: (state, action) => {
			state.loadingEvent = false;
		},
		[getAllEvent.fulfilled]: (state, action) => {
			state.loadingEvent = false;
			state.eventList = action.payload;
		},
		[getAllEventWithPag.pending]: (state, action) => {
			state.loadingEvent = true;
		},
		[getAllEventWithPag.rejected]: (state, action) => {
			state.loadingEvent = false;
		},
		[getAllEventWithPag.fulfilled]: (state, action) => {
			state.loadingEvent = false;
			state.eventListPag = action.payload.data;
			state.total = action.payload.total_items;
		},
	},
});

export default eventSlice;
