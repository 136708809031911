import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import productsApi from "../../api/productsApi";

export const productsGetAll = createAsyncThunk("products/productGetAll", async () => {
	const listProduct = await productsApi.getAll();
	return listProduct;
});

export const productsGetAllPaging = createAsyncThunk("products/productsGetAllPaging", async ({ filter }) => {
	let txt = "";
	for (let x in filter) {
		if (filter[x] !== "") {
			txt += `&${x}=${filter[x]}`;
		}
	}
	const list = await productsApi.getForUsingPagination(txt);
	return list;
});

const productSlice = createSlice({
	name: "product",
	initialState: {
		productList: [],
		productListWithPagination: {
			data: [],
		},
		product: {},
		loadingProduct: false,
		loadingProductWithPaging: false,
		error: "",
	},
	reducers: {},
	extraReducers: {
		[productsGetAll.pending]: (state, action) => {
			state.loadingProduct = true;
		},
		[productsGetAll.rejected]: (state, action) => {
			state.loadingProduct = false;
		},
		[productsGetAll.fulfilled]: (state, action) => {
			state.loadingProduct = false;
			state.productList = action.payload;
		},
		[productsGetAllPaging.pending]: (state, action) => {
			state.loadingProductWithPaging = true;
		},
		[productsGetAllPaging.rejected]: (state, action) => {
			state.loadingProductWithPaging = false;
		},
		[productsGetAllPaging.fulfilled]: (state, action) => {
			state.loadingProductWithPaging = false;
			state.productListWithPagination = action.payload;
		},
	},
});

export default productSlice;
