import axiosClient from "./axiosClient";

const brandsApi = {
  async getAll() {
    const url = "/brands";
    return axiosClient.get(url);
  },
  async getById(id) {
    const url = `/brands/${id}`;
    return axiosClient.get(url);
  },
  async getForUsingPagination(current, size) {
    const url = `/brands/all-paging?page_index=${current}&page_size=${size}`;
    return axiosClient.get(url);
  },
  async create(data) {
    const url = "/brands";
    return axiosClient.post(url, data);
  },
  async edit(edit) {
    const url = `/brands/${edit.id}`;
    return axiosClient.put(url, edit);
  },
  async delete(id) {
    const url = `/brands/${id}`;
    return axiosClient.delete(url);
  },
};

export default brandsApi;
