import axiosClient from "./axiosClient";

const ordersApi = {
	async getAll() {
		const url = "/orders";
		return axiosClient.get(url);
	},
	async getAllwithPag(index, size) {
		const url = `/orders/all-paging?page_index=${index}&page_size=${size}`;
		return axiosClient.get(url);
	},
	async getDetailOrder(id) {
		const url = `/orders/${id}`;
		return axiosClient.get(url);
	},
	async updateOrder(id, data) {
		const url = `/orders/${id}`;
		return axiosClient.put(url, data);
	},
	async statusOrder(id, data) {
		const url = `/orders/status/${id}`;
		return axiosClient.put(url, data);
	},
	// async deleteOrder(id) {
	//   const url = `/orders/${id}`;
	//   return axiosClient.delete(url);
	// },
};

export default ordersApi;
