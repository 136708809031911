import React, { useEffect } from "react";
import "./Login.scss";
import { useState } from "react";
import usersApi from "../../api/usersApi";
import { useNavigate } from "react-router-dom";
import banner from "../../assets/images/login/banner-login.png";
import logo from "../../assets/images/login/logo.svg";
import ToastPopup, { notifyError, notifySuccess } from "../../toast/toast";
import { useSelector } from "react-redux";

function Login() {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();

	const { isAuth } = useSelector((state) => state.userReducer);

	useEffect(() => {
		isAuth && navigate("/");
	}, [isAuth, navigate]);

	const handleLogin = async (e) => {
		e.preventDefault();
		try {
			const res = await usersApi.login({
				user_name: username,
				password,
			});
			console.log(res);
			if (res.message === "error") notifyError("Đăng nhập không thành công, Vui lòng nhập lại!");
			else {
				notifySuccess("Đăng nhập thành công");
				localStorage.setItem("user", JSON.stringify(res));
				localStorage.setItem("access_token", JSON.stringify(res.access_token));
				localStorage.setItem("refresh_token", JSON.stringify(res.refresh_token));
				localStorage.setItem("info", JSON.stringify(res));
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			}
		} catch (error) {
			//console.log(error);
			notifyError("Đăng nhập không thành công");
		}
	};
	return (
		<div className="login-admin">
			<ToastPopup />
			<div className="login-banner">
				<img src={banner} alt="" />
			</div>
			<div className="login-form">
				<form action="" onSubmit={handleLogin}>
					<div className="login-form-detail">
						<img src={logo} alt="" />
						<p className="smiletech-admin">Smiletech Admin</p>
						<p className="please-input-form">Xin chào, vui lòng nhập thông tin đăng nhập</p>
						<div>
							<label>TÊN ĐĂNG NHẬP</label>
							<br />
							<input
								type="text"
								placeholder="Tên Đăng Nhập"
								onChange={(e) => {
									setUsername(e.target.value);
								}}
							/>
							<br />
						</div>
						<div className="login-form-forget">
							<label>MẬT KHẨU</label>
							<br />
							<input
								type="password"
								placeholder="Mật khẩu"
								onChange={(e) => {
									setPassword(e.target.value);
								}}
							/>
							<br />
						</div>
						<a href="/forgetpass">Quên mật khẩu?</a>

						<button type="submit" className="submit">Đăng nhập</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default Login;
