import ordersApi from "../../api/ordersApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export const getAllOrder = createAsyncThunk("getAllOrder/order", async () => {
	const getAll = await ordersApi.getAll();
	return getAll;
});
export const getAllOrderWithPag = createAsyncThunk("getAllOrderWithPag/order", async ({ index, size }) => {
	const res = await ordersApi.getAllwithPag(index, size);
	return res;
});
export const getDetailOrder = createAsyncThunk("getDetailOrder/order", async (id) => {
	const detail = await ordersApi.getDetailOrder(id);
	return detail;
});
export const updateDetailOrder = createAsyncThunk("updateDetailOrder/order", async (ob) => {
	const { data, id } = ob;
	const update = await ordersApi.updateOrder(id, data);

	return update;
});

const orderSlice = createSlice({
	name: "orders",
	initialState: {
		listOrder: [],
		listOrderPag: [],
		loading: false,
		detailOrder: {},
	},
	reducers: {
		setDetailOrder: (state, action) => {
			state.detailOrder = action.payload;
		},
	},
	extraReducers: {
		[getAllOrder.pending]: (state) => {
			state.loading = true;
		},
		[getAllOrder.rejected]: (state) => {
			state.loading = false;
		},
		[getAllOrder.fulfilled]: (state, action) => {
			state.loading = false;
			state.listOrder = action.payload;
		},
		[getAllOrderWithPag.pending]: (state) => {
			state.loading = true;
		},
		[getAllOrderWithPag.rejected]: (state) => {
			state.loading = false;
		},
		[getAllOrderWithPag.fulfilled]: (state, action) => {
			state.loading = false;
			state.listOrderPag = action.payload.data;
			state.total = action.payload.total_items;
		},
		[getDetailOrder.pending]: (state) => {
			state.loading = true;
		},
		[getDetailOrder.rejected]: (state) => {
			state.loading = false;
		},
		[getDetailOrder.fulfilled]: (state, action) => {
			state.loading = false;
			state.detailOrder = action.payload;
		},
	},
});

export const { setDetailOrder } = orderSlice.actions;
export default orderSlice;
