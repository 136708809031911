import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import projectsApi from "../../api/projectApi";

const getAllProject = createAsyncThunk('project/getAllProject', async(data) => {
    const projectList = await projectsApi.getAllProject(data)
    return projectList
})

export const getAllProjectPaging = createAsyncThunk("project/getAllProjectPaging", async (data) => {
    const list = await projectsApi.getForUsingPagination(data.current, data.size);
      // console.log(list);
    return list;
})

export const getProjectById = createAsyncThunk("project/getProjectById", async(data) => {
    const projectById = await projectsApi.getProjectById(data.id)
    return projectById
})

// export const addProject = createAsyncThunk("project/get")

const projectSlice = createSlice({
    name: 'project',
    initialState: {
        loading: false,
        projectList: [],
        projectListWithPagination: [],
        projectById: {}
    },
    extraReducers: {
        [getAllProject.pending] : (state, action) => {
            state.loading = true
        },
        [getAllProject.rejected] : (state, action) => {
            state.loading = false
        },
        [getAllProject.fulfilled] : (state, action) => {
            state.loading = false
            state.projectList = action.payload.data
        },
        [getAllProjectPaging.pending]: (state, action) => {
            state.loading = true;
          },
        [getAllProjectPaging.rejected]: (state, action) => {
            state.loading = false;
          },
        [getAllProjectPaging.fulfilled]: (state, action) => {
            // console.log(action.payload);
            state.loading = false;
            state.projectListWithPagination = action.payload.data;
        },
        [getProjectById.pending]: (state, action) => {
            state.loading = true;
          },
        [getProjectById.rejected]: (state, action) => {
            state.loading = false;
          },
        [getProjectById.fulfilled]: (state, action) => {
            // console.log(action.payload);
            state.loading = false;
            state.projectById = action.payload;
        },
    }
})

export default projectSlice
export { getAllProject }