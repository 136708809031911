import React from "react";
import { useNavigate } from "react-router-dom";
import "./notfound.scss";
import arrowBack from "../../assets/icon/arrowBack.svg";
import notFound from "../../assets/icon/notFound.svg";

function Error({ isProduct }) {
  const navigate = useNavigate();
  return (
    <div className="notFound">
      <div className="notFound__header">
        <div className="notFound__header-wrapper">
          <button onClick={() => navigate(-1)}>
            <img src={arrowBack} alt="arrowBack" />
          </button>
        </div>
      </div>
      <div className="notFound__body">
        <div className="notFound__body-wrapper">
          <img src={notFound} alt="notFound" />
          <h1>404</h1>
          <p>It looks like something is missing</p>
        </div>
      </div>
    </div>
  );
}

export default Error;
