import axiosClient from "./axiosClient";

const usersApi = {
	async login(data) {
		const url = `/users/login`;
		return axiosClient.post(url, data);
	},
	async getListUser() {
		const url = `/users`;
		return axiosClient.get(url);
	},
	async getAllwithPag(index, size) {
		const url = `/users/all-paging?page_index=${index}&page_size=${size}`;
		return axiosClient.get(url);
	},
	async createUser(data) {
		const url = `/users/create`;
		return axiosClient.post(url, data);
	},
	async deleteUser(id) {
		const url = `/users/${id}`;
		return axiosClient.delete(url);
	},
	async updateUser(id, data) {
		const url = `/users/${id}`;
		return axiosClient.put(url, data);
	},
	async getDetailUser(id) {
		const url = `/users/${id}`;
		return axiosClient.get(url);
	},
	async getRol() {
		const url = `/roles`;
		return axiosClient.get(url);
	},
};

export default usersApi;
