import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import brandsApi from "../../api/brandsApi";

export const getAllBrands = createAsyncThunk(
  "brands/getAllBrands",
  async () => {
    const brandList = await brandsApi.getAll();
    console.log("brandlist: ", brandList);
    return brandList;
  }
);

export const getAllBrandPaging = createAsyncThunk(
  "brands/getAllBrandsPaging",
  async ({ current, size }) => {
    const list = await brandsApi.getForUsingPagination(current, size);
    console.log("list: ", list);
    return list;
  }
);

const brandSlice = createSlice({
  name: "brand",
  initialState: {
    brandList: [],
    brandListAllPaging: {
      data: [],
    },
    loadingBrandAllPaging: false,
    loadingBrand: false,
    error: "",
  },
  reducers: {},
  extraReducers: {
    [getAllBrands.pending]: (state, action) => {
      state.loadingBrand = true;
    },
    [getAllBrands.rejected]: (state, action) => {
      state.loadingBrand = false;
    },
    [getAllBrands.fulfilled]: (state, action) => {
      state.loadingBrand = false;
      state.brandList = action.payload;
    },
    [getAllBrandPaging.pending]: (state, action) => {
      state.loadingBrandAllPaging = true;
    },
    [getAllBrandPaging.rejected]: (state, action) => {
      state.loadingBrandAllPaging = false;
    },
    [getAllBrandPaging.fulfilled]: (state, action) => {
      state.loadingBrandAllPaging = false;
      state.brandListAllPaging = action.payload;
    },
  },
});

export default brandSlice;
