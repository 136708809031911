import axiosClient from "./axiosClient"

const cooperationPackageApi = {
    getAllPaging(current, size) {
        const url = `/cooperation-package/all-paging/all?page_index=${current}&page_size=${size}`
        return axiosClient.get(url)
    },
    getById(id) {
        const url = `/cooperation-package/${id}`
        return axiosClient.get(url)
    },
    create(data) {
        console.log(data);
        const url = `/cooperation-package/`;
        return axiosClient.post(url, data, {
            headers: {
                ecommerce_id: data.ecommerce_id
            }
        });
    },
    Delete(id) {
        const url = `/cooperation-package/${id}`;
        return axiosClient.delete(url);
    },
    Edit(edit) {
        console.log(edit);
        const url = `/cooperation-package/${edit.id}`;
        return axiosClient.put(url, edit);
    },
}

export default cooperationPackageApi