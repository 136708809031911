import { configureStore } from "@reduxjs/toolkit";
import addressSlice from "./categories/addressSlice";
import categorySlice from "./categories/categorySlice";
import userSlice from "./login/userSlice";
import notificationSlice from "./categories/notificationSlice";
import newsSlice from "./news/news";
// import userSlice from "./login/userSlice";
import productSlice from "./categories/productSlice";
import storeSlice from "./categories/storesSlice";
import ecommerceSlice from "./ecommerce/ecommerce";
import orderSlice from "./order/orderSlice";
import brandSlice from "./categories/brandSlice";
import groupSlice from "./categories/groupSlice";
import eventSlice from "./categories/eventSlice";
import propertySlice from "./categories/propertySlice";
import voucherSlice from "./categories/voucherSlice";
import bannerSlice from "./categories/bannerSlice";
import customerSlice from "./categories/customerSlice";
import fieldsSlice from "./categories/fieldsSlice";
import projectSlice from "./categories/projectSlice";
import supplierSlice from "./categories/supplierSlice";
import countrySlice from "./categories/countrySlice";
import cooperationPackageSlice from "./categories/cooperationPackageSlice";
const store = configureStore({
	reducer: {
		productReducer: productSlice.reducer,
		userReducer: userSlice.reducer,
		orderReducer: orderSlice.reducer,
		storeReducer: storeSlice.reducer,
		categoryReducer: categorySlice.reducer,
		notificationReducer: notificationSlice.reducer,
		addressReducer: addressSlice.reducer,
		ecommerceReducer: ecommerceSlice.reducer,
		brandReducer: brandSlice.reducer,
		newsReducer: newsSlice.reducer,
		groupsReducer: groupSlice.reducer,
		eventsReducer: eventSlice.reducer,
		propertyReducer: propertySlice.reducer,
		voucherReducer: voucherSlice.reducer,
		bannerReducer: bannerSlice.reducer,
		customerReducer: customerSlice.reducer,
		fieldsReducer: fieldsSlice.reducer,
		projectReducer: projectSlice.reducer,
		supplierReducer: supplierSlice.reducer,
		countryReducer: countrySlice.reducer,
		cooperationPackageReducer: cooperationPackageSlice.reducer
	},
});

export default store;
