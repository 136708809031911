import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import supplierApi from "../../api/supplierApi"

const getAllSupplier = createAsyncThunk('supplier/getAllSupplier', async() => {
    const listSupplier = supplierApi.getAllSupplier()
    return listSupplier
})

const supplierSlice = createSlice({
    name: 'supplier',
    initialState: {
        loading: false,
        listSupplier: []
    },
    extraReducers: {
        [getAllSupplier.pending] : (state, action) => {
            state.loading = true
        },
        [getAllSupplier.rejected] : (state, action) => {
            state.loading = false
        },
        [getAllSupplier.fulfilled] : (state, action) => {
            state.loading = false
            state.listSupplier = action.payload
        }
    }
})

export default supplierSlice
export {getAllSupplier}