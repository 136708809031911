import custommers from "../assets/sidebar/custommers.svg";
import inventory from "../assets/sidebar/inventory.svg";
import gift from "../assets/sidebar/gift.svg";
import products from "../assets/sidebar/products.svg";
import ship from "../assets/sidebar/ship.svg";
import box from "../assets/sidebar/box.svg";
import shop from "../assets/sidebar/shop.svg";
import notification from "../assets/sidebar/notification.svg";
import ecommerce from "../assets/images/ecommerce/ecommerce.svg";
import groupsIcon from "../assets/icon/groups.svg";

const ROLES = {
  admin: 1,
  customer: 2,
  adminEcommerce: 3,
  subAdminEcommerce: 4,
  adminStore: 5,
  subAdminStore: 6,
};

export const adminMenu = [
  {
    name: "report",
    title: "Báo cáo",
    icon: box,
    link: "/",
  },
  {
    name: "ecommerce",
    title: "Quản lý các sàn",
    icon: ecommerce,
    link: "/ecommerce",
    roles: [ROLES.admin],
  },
  {
    name: "orders",
    title: "Đơn hàng",
    icon: shop,
    children: [
      {
        name: "all",
        title: "Tất cả",
        icon: "",
        link: "/orders",
      },
      {
        name: "pending",
        title: "Chưa hoàn tất",
        icon: "",
        link: "/",
      },
    ],
  },
  {
    name: "stores",
    title: "Cửa hàng",
    icon: shop,
    link: "/stores/all",
    roles: [ROLES.admin, ROLES.adminEcommerce],
  },
  {
    name: "brands",
    title: "Thương hiệu",
    icon: shop,
    link: "/brands/all",
  },
  {
    name: "groups",
    title: "Hội nhóm",
    icon: groupsIcon,
    link: "/groups/all",
    roles: [ROLES.admin, ROLES.adminEcommerce],
  },
  {
    name: "products",
    title: "Sản phẩm",
    icon: products,
    children: [
      {
        name: "all-product",
        title: "Tất cả sản phẩm",
        link: "/products/all",
      },
      {
        name: "categories",
        title: "Loại sản phẩm",
        link: "/products/category/all",
      },
      {
        name: "properties",
        title: "Thuộc tính sản phẩm",
        link: "/products/properties/all",
      },
    ],
  },
  {
    name: "user",
    title: "User",
    icon: custommers,
    link: "/users/",
    roles: [ROLES.admin, ROLES.adminEcommerce],
  },
  {
    name: "news-manage",
    title: "Quản lý tin tức",
    icon: inventory,
    link: "/news/all",
    roles: [ROLES.admin, ROLES.adminEcommerce],
    // children: [],
  },
  {
    name: "vouchers",
    title: "Khuyến mãi",
    icon: gift,
    link: "/vouchers/all",
    roles: [ROLES.admin, ROLES.adminEcommerce],
  },
  {
    name: "notifications",
    title: "Thông báo",
    icon: notification,
    link: "/notifications/all",
    roles: [ROLES.admin, ROLES.adminEcommerce],
  },
  {
    name: "events",
    title: "Sự kiện",
    icon: notification,
    link: "/events/all",
    roles: [ROLES.admin, ROLES.adminEcommerce],

  },
  {
    name: "banners",
    title: "Banners",
    icon: gift,
    link: "/banners/all",
    roles: [ROLES.admin, ROLES.adminEcommerce],
  },
  {
    name: "customers",
    title: "Khách hàng",
    icon: custommers,
    link: "/customers/all",
  },
  {
    name: "project",
    title: "Dự án",
    icon: box,
    link: "/project/all",
  },
  {
    name: "cooperationPackage",
    title: "Gói hợp tác",
    icon: box,
    link: "/cooperationPackage/all",
  },
];
// export const adminSiteMenu = [
//   {
//     name: "report",
//     title: "Báo cáo",
//     icon: box,
//     link: "/",
//   },
//   {
//     name: "ecommerce",
//     title: "Quản lý các sàn1",
//     icon: ecommerce,
//     link: "/ecommerce",
//     roles: [ROLES.adminEcommerce],
//   },
//   {
//     name: "orders",
//     title: "Đơn hàng",
//     icon: shop,
//     children: [
//       {
//         name: "all",
//         title: "Tất cả",
//         icon: "",
//         link: "/orders",
//       },
//       {
//         name: "pending",
//         title: "Chưa hoàn tất",
//         icon: "",
//         link: "/",
//       },
//     ],
//   },
//   {
//     name: "stores",
//     title: "Cửa hàng",
//     icon: shop,
//     link: "/stores/all",
//   },
//   {
//     name: "brands",
//     title: "Thương hiệu",
//     icon: shop,
//     link: "/brands/all",
//   },
//   {
//     name: "groups",
//     title: "Hội nhóm",
//     icon: groupsIcon,
//     link: "/groups/all",
//   },
//   {
//     name: "products",
//     title: "Sản phẩm",
//     icon: products,
//     children: [
//       {
//         name: "all-product",
//         title: "Tất cả sản phẩm",
//         link: "/products/all",
//       },
//       {
//         name: "categories",
//         title: "Loại sản phẩm",
//         link: "/products/category/all",
//       },
//       {
//         name: "properties",
//         title: "Thuộc tính sản phẩm",
//         link: "/products/properties/all",
//       },
//     ],
//   },
//   {
//     name: "user",
//     title: "User",
//     icon: custommers,
//     link: "/users/",
//     roles: [ROLES.admin, ROLES.adminEcommerce],
//   },
//   {
//     name: "news-manage",
//     title: "Quản lý tin tức",
//     icon: inventory,
//     link: "/news/all",
//     // children: [],
//   },
//   {
//     name: "vouchers",
//     title: "Khuyến mãi",
//     icon: gift,
//     link: "/vouchers/all",
//   },
//   {
//     name: "notifications",
//     title: "Thông báo",
//     icon: notification,
//     link: "/notifications/all",
//   },
//   {
//     name: "events",
//     title: "Sự kiện",
//     icon: notification,
//     link: "/events/all",
//   },
//   {
//     name: "banners",
//     title: "Banners",
//     icon: gift,
//     link: "/banners/all",
//   },
//   {
//     name: "customers",
//     title: "Khách hàng",
//     icon: custommers,
//     link: "/customers/all",
//   },
// ];
