import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StoresApi from "../../api/storesApi";

export const getAllStores = createAsyncThunk("store/getAllStores", async () => {
  const listStore = await StoresApi.getAll();
  return listStore;
});

export const getAllStorePaging = createAsyncThunk(
  "store/getAllStorePaging",
  async (data) => {
    const list = await StoresApi.getForUsingPagination(data.current, data.size);
    // console.log(list);
    return list;
  }
);

export const getAllStoresByEcommerce = createAsyncThunk("store/getAllStoresByEcommerce", async (data) => {
  console.log(data.field_id);
  const listStoreByEcommerce = await StoresApi.getAllByEcommerce(data.field_id);
  return listStoreByEcommerce;
});

const storeSlice = createSlice({
  name: "stores",
  initialState: {
    storeList: [],
    storeListByEcommerce: [],
    storeListWithPagination: {
      data: [],
    },
    loadingStoreWithPagination: false,
    loadingStore: false,
    error: "",
  },
  reducers: {},
  extraReducers: {
    [getAllStores.pending]: (state, action) => {
      state.loadingStore = true;
    },
    [getAllStores.rejected]: (state, action) => {
      state.loadingStore = false;
    },
    [getAllStores.fulfilled]: (state, action) => {
      state.loadingStore = false;
      state.storeList = action.payload;
    },
    [getAllStorePaging.pending]: (state, action) => {
      state.loadingStoreWithPagination = true;
    },
    [getAllStorePaging.rejected]: (state, action) => {
      state.loadingStoreWithPagination = false;
    },
    [getAllStorePaging.fulfilled]: (state, action) => {
      state.loadingStoreWithPagination = false;
      state.storeListWithPagination = action.payload;
    },
    [getAllStoresByEcommerce.pending]: (state, action) => {
      state.loadingStore = true;
    },
    [getAllStoresByEcommerce.rejected]: (state, action) => {
      state.loadingStore = false;
    },
    [getAllStoresByEcommerce.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.loadingStore = false;
      state.storeListByEcommerce = action.payload.data;
    },
  },
});

export default storeSlice;
