import axiosClient from "./axiosClient";

const addressApi = {
  getAllCity() {
    const url = `/cities`;
    return axiosClient.get(url);
  },
  getAllDistricts(city_id) {
    const url = `/districts/city/${city_id}`;
    return axiosClient.get(url);
  },
  getAllWards(id_district) {
    const url = `/wards/district/${id_district}`
    return axiosClient.get(url)
  }
};

export default addressApi;
