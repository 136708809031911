import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { routes } from "./routers/index";
import { Spin } from "antd";
import ForgetPassword from "./pages/login/ForgetPassword/ForgetPassword";
import DefaultLayout from "./layouts/defaultLayout/index";
// import ProtectedRouter from "./helpers/ProtectedRouter";
import Login from "./pages/login";
import "react-toastify/dist/ReactToastify.css";
import RequireAuth from "./routers/RequireAuth";
import Error from "./pages/error";

function App() {
  // roles
  // const ROLES = {
  //   admin: 1,
  //   customer: 2,
  //   adminEcommerce: 3,
  //   subAdminEcommerce: 4,
  //   adminStore: 5,
  //   subAdminStore: 6,
  // };

  const user =
    localStorage.getItem("user") === null
      ? null
      : JSON.parse(localStorage.getItem("user"));

  const userRole = user === null ? null : user.type;

  return (
    <div className="App">
      <div className="routers">
        <Suspense
          fallback={
            <Spin
              size="large"
              style={{ position: "fixed", top: "49%", left: "49%" }}
            />
          }
        >
          <Routes>
            {/* protected routes */}
            <Route element={<RequireAuth />}>
              {routes.map((route, index) => {
                const Page = route.component;
                let Layout = DefaultLayout;
                // let roles;

                // if (route.layout) {
                //   Layout = route.layout;
                // } else if (route.layout === null) {
                //   Layout = <></>;
                // }

                if (route.roles) {
                  if (!route.roles.find((role) => role === userRole)) {
                    return (
                      <Route key={index} path="/error" element={<Error />} />
                    );
                  }
                }
                return (
                  <Route
                    key={`id-routes-${index}`}
                    path={route.path}
                    element={
                      <Layout>
                        <Page />
                      </Layout>
                    }
                  />
                );
              })}
            </Route>
            {/* public routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/forgetpass" element={<ForgetPassword />} />

            <Route path="/*" element={<Error />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}

export default App;
