import React from 'react';
import banner from "../../../assets/images/login/banner-login.png";
import logo from "../../../assets/images/login/logo.svg";

function ForgetPassword() {
  return (
    <div className="login-admin">
      <div className="login-banner">
        <img src={banner} alt="" />
      </div>
      <div className="login-form">
        <div className="login-form-detail">
          <img src={logo} alt="" />
          <p className="smiletech-admin">Bạn quên mật khẩu?</p>
          <p className="please-input-form">
          Nhập email của bạn để tiến hành lấy lại mật khẩu
          </p>
           <div>
            <label>EMAIL</label>
            <br />
            <input
              type="text"
              placeholder="Email"
            //   onChange={(e) => {
            //     setUsername(e.target.value);
            //   }}
            />
            <br />
          </div>
          <button>Lấy lại mật khẩu</button>
          <a href='/login' className='back-to-login'>Quay lại</a>
          {/* <p>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p> */}
        </div>
      </div>
    </div>
  )
}

export default ForgetPassword