import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GroupsApi from "../../api/groupsApi";

export const getAllGroup = createAsyncThunk("groups/getAllGroup", async () => {
	const groupList = await GroupsApi.getAll();
	console.log("groupList: ", groupList);
	return groupList;
});
export const getAllTeamWithPag = createAsyncThunk("getAllTeamWithPag/team", async ({ index, size }) => {
	const res = await GroupsApi.getAllwithPag(index, size);
	console.log(res);
	return res;
});

const groupSlice = createSlice({
	name: "groups",
	initialState: {
		groupList: {},
		groupListPag: [],
		loadingGroup: false,
		error: "",
	},
	reducers: {},
	extraReducers: {
		[getAllGroup.pending]: (state, action) => {
			state.loadingGroup = true;
		},
		[getAllGroup.rejected]: (state, action) => {
			state.loadingGroup = false;
		},
		[getAllGroup.fulfilled]: (state, action) => {
			state.loadingGroup = false;
			state.groupList = action.payload;
		},
		[getAllTeamWithPag.pending]: (state, action) => {
			state.loadingGroup = true;
		},
		[getAllTeamWithPag.rejected]: (state, action) => {
			state.loadingGroup = false;
		},
		[getAllTeamWithPag.fulfilled]: (state, action) => {
			state.loadingGroup = false;
			state.groupListPag = action.payload.data;
			state.total = action.payload.total_items;
		},
	},
});

export default groupSlice;
