import React from "react";
import SideBar from "../sidebar/index";
import Header from "../header/index";
import "./defaultLayout.scss";

function DefaultLayout({ children }) {
  return (
    <div className="default-layout">
      <SideBar />
      <div className="container">
        <Header />
        <div className="content">{children}</div>
      </div>
    </div>
  );
}

export default DefaultLayout;
