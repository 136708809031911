import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import customerApi from "../../api/customerApi";

export const getListCustomer = createAsyncThunk("getListCustomer/all", async () => {
	const list = await customerApi.getListCustomer();
	return list;
});
export const getAllcustomerPaging = createAsyncThunk("getAllcustomerPaging/customer", async ({ index, size }) => {
	const list = await customerApi.getCustomerWithPag(index, size);
	return list;
});
export const getDetail = createAsyncThunk("getDetail", async () => {
	const res = await customerApi.getDetail();
	return res;
});

const customerSlice = createSlice({
	name: "customer",
	initialState: {
		listCustomer: [],
		listCustomerPag: [],
		loading: false,
	},
	reducers: {},
	extraReducers: {
		[getListCustomer.pending]: (state) => {
			state.loading = true;
		},
		[getListCustomer.rejected]: (state) => {
			state.loading = false;
		},
		[getListCustomer.fulfilled]: (state, action) => {
			state.loading = false;
			state.listCustomer = action.payload;
		},
		[getAllcustomerPaging.pending]: (state) => {
			state.loading = true;
		},
		[getAllcustomerPaging.rejected]: (state) => {
			state.loading = false;
		},
		[getAllcustomerPaging.fulfilled]: (state, action) => {
			state.loading = false;
			state.listCustomerPag = action.payload.data;
			state.total = action.payload.total_items;
		},
	},
});

export default customerSlice;
