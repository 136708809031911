import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import addressApi from "../../api/addressApi";

export const getAllCities = createAsyncThunk(
  "address/getAllCities",
  async () => {
    const cityList = await addressApi.getAllCity();
    // console.log("cityList: ", cityList);
    return cityList;
  }
);

export const getAllDistricts = createAsyncThunk(
  "address/getAllDistricts",
  async (city_id) => {
    const districtList = await addressApi.getAllDistricts(city_id);
    // console.log("districtList: ", districtList);
    return districtList;
  }
);

export const getAllWards = createAsyncThunk('address/getAllWards', async(id_district) => {
  // console.log(id_district);
  const wardList = await addressApi.getAllWards(id_district)
  return wardList
})

const addressSlice = createSlice({
  name: "address",
  initialState: {
    city: 1581130,
    district: 0,
    ward: 0,
    cityList: [],
    districtList: [],
    wardList: [],
    loadingCity: false,
    loadingDistrict: false,
    loadingWard: false,
    error: "",
  },
  reducers: {
    setCity: (state, action) => {
      return {
        ...state, 
        city: action.payload
      }
    },
    setDistrict: (state, action) => {
      state.district = action.payload;
    },
    setWard: (state, action) => {
      state.ward = action.payload
    }
  },
  extraReducers: {
    [getAllCities.pending]: (state, action) => {
      state.loadingCity = true;
    },
    [getAllCities.rejected]: (state, action) => {
      state.loadingCity = false;
    },
    [getAllCities.fulfilled]: (state, action) => {
      state.loadingCity = false;
      state.cityList = action.payload;
    },
    [getAllDistricts.pending]: (state, action) => {
      state.loadingDistrict = true;
    },
    [getAllDistricts.rejected]: (state, action) => {
      state.loadingDistrict = false;
    },
    [getAllDistricts.fulfilled]: (state, action) => {
      state.loadingDistrict = false;
      state.districtList = action.payload;
    },
    [getAllWards.pending]: (state, action) => {
      state.loadingWard = true;
    },
    [getAllWards.rejected]: (state, action) => {
      state.loadingWard = false;
    },
    [getAllWards.fulfilled]: (state, action) => {
      state.loadingWard = false;
      state.wardList = action.payload
    }
  },
});

export default addressSlice;

export const {setCity, setDistrict, setWard} = addressSlice.actions
