import React, { memo } from "react";
import "./header.scss";
import arrowLight from "../../assets/icon/arrow-light.svg";
import search from "../../assets/icon/search.svg";
import avatar from "../../assets/icon/avatar.svg";
import { Link } from "react-router-dom";

function Header() {
  const user_data = JSON.parse(localStorage.getItem("user")) || "";

  const checkImage = (image) => {
    if (image === null || image === undefined) return avatar;
    else return process.env.REACT_APP_API_URL + image;
  };

  return (
    <div className="header">
      <div className="header__link">
        {/* <Link to="/">Đơn hàng</Link>
        <img src={arrowLight} alt="arrow" />
        <Link to="/">Danh sách đơn hàng</Link> */}
      </div>
      <div className="header__info">
        <div className="header__search">
          <img src={search} alt="search" />
          <input type="text" placeholder="Nhập từ khóa" />
        </div>
        <div className="header__profile">
          <div className="header__username">
            <p>{user_data.user_name}</p>
          </div>
          <div className="header__avatar">
            <img src={checkImage(user_data.avatar)} alt="avatar" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Header);
