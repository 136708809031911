import axiosClient from "./axiosClient";

const StoresApi = {
  getAll() {
    const url = `/stores`;
    return axiosClient.get(url);
  },
  getForUsingPagination(current, size) {
    const url = `stores/all-paging?page_index=${current}&page_size=${size}`;
    return axiosClient.get(url)
  },
  getAllByEcommerce(field_id) {
    console.log(field_id);
    const url = `stores/all-paging?field_id=${field_id}`;
    return axiosClient.get(url)
  },
  get(id) {
    const url = `/stores/${id}`;
    return axiosClient.get(url);
  },
  create(data) {
    const url = `/stores`;
    return axiosClient.post(url, data);
  },
  Edit(edit) {
    const url = `/stores/${edit.id}`;
    return axiosClient.put(url, edit);
  },
  Delete(id) {
    const url = `/stores/${id}`;
    return axiosClient.delete(url);
  },
};
export default StoresApi;
