import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import newsApi from "../../api/newsApi";

export const getAllNews = createAsyncThunk("getAllNews/news", async () => {
	const getall = await newsApi.getAll();
	console.log(getall);
	return getall;
});
export const getAllNewWithPag = createAsyncThunk("getAllNewWithPag/new", async ({ index, size }) => {
	const res = await newsApi.getAllwithPag(index, size);
	return res;
});
const newsSlice = createSlice({
	name: "news",
	initialState: {
		listNews: [],
		listNewsPag: [],
		loading: false,
	},
	reducers: {},
	extraReducers: {
		[getAllNews.pending]: (state) => {
			state.loading = true;
		},
		[getAllNews.rejected]: (state) => {
			state.loading = false;
		},
		[getAllNews.fulfilled]: (state, action) => {
			state.loading = false;
			state.listNews = action.payload;
		},
		[getAllNewWithPag.pending]: (state) => {
			state.loading = true;
		},
		[getAllNewWithPag.rejected]: (state) => {
			state.loading = false;
		},
		[getAllNewWithPag.fulfilled]: (state, action) => {
			state.loading = false;
			state.listNewsPag = action.payload.data;
			state.total = action.payload.total_items;
		},
	},
});

export default newsSlice;
