import React, { memo, useState } from "react";
import "./sidebar.scss";
// import { useNavigate } from "react-router-dom";
import { adminMenu } from "../../menu/admin-menu";
import setting from "../../assets/icon/setting.svg";
import logout from "../../assets/icon/logout.svg";
import { Link } from "react-router-dom";
import "boxicons/css/boxicons.min.css";

const Accordion = ({ data }) => {
	const [active, setActive] = useState(false);
	const onClick = (e) => {
		e.preventDefault();
		setActive(!active);
	};
	return (
		<div className={`sidebar__tab ${active ? "active" : ""}`}>
			<div className="sidebar__tab-wrapper" onClick={onClick}>
				<div className="sidebar__img-container">
					<img src={data.icon} alt="icon" />
				</div>
				<p className="sidebar__tab-title">{data.title}</p>
				{data.children && (
					<div className="sidebar__tab-extend">
						<i className="bx bxs-chevron-down"></i>
					</div>
				)}
			</div>
			<div className="sidebar__tab-wrapperChild">
				{data.children.map((child, index) => {
					return (
						<div className="sidebar__tab-child" key={index}>
							<Link to={child.link}>
								<p className="sidebar__tab-child--title">{child.title}</p>
							</Link>
						</div>
					);
				})}
			</div>
		</div>
	);
};

function SideBar() {
	const user = localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : null;
	const userRole = user === null ? null : user.type;

	const menu = adminMenu.map((item, index) => {
		if (!item.roles || (item.roles && item.roles.find((role) => role === userRole))) {
			if (!item.children)
				return (
					<div className="sidebar__tab" key={item.name}>
						<Link to={item.link}>
							<div className="sidebar__tab-wrapper">
								<div className="sidebar__img-container">
									<img src={item.icon} alt="icon" />
								</div>
								<p className="sidebar__tab-title">{item.title}</p>
							</div>
						</Link>
					</div>
				);
			return <Accordion key={index} data={item} />;
		}
	});

	return (
		<div className="sidebar">
			<div className="sidebar__logo">Dashboard</div>
			<div className="sidebar__menu">{menu}</div>
			<div className="sidebar__setting">
				<img src={setting} alt="" />
				<Link to="/setting">
					<p>Cài đặt</p>
				</Link>
			</div>
			<div
				className="sidebar__setting"
				onClick={() => {
					localStorage.clear();
					// navigate("/login");
					window.location.reload();
				}}
			>
				<img src={logout} alt="" />
				<Link to="/login">
					<p>Đăng xuất</p>
				</Link>
			</div>
		</div>
	);
}

export default memo(SideBar);
