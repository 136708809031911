import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cooperationPackageApi from "../../api/cooperationPackageApi";

export const getAllPackagePaging = createAsyncThunk("cooperationPackage/getAllPackagePaging", async(data) => {
    const listDataPackagePaging = cooperationPackageApi.getAllPaging(data.curent, data.size)
    return listDataPackagePaging
})

const cooperationPackageSlice = createSlice({
    name: "cooperationPackageSlice",
    initialState: {
        loading: false,
        listAllPackagePaging: [],
    },
    extraReducers: {
        [getAllPackagePaging.pending] : (state, action) => {
            state.loading = true
        },
        [getAllPackagePaging.rejected] : (state, action) => {
            state.loading = false
        },
        [getAllPackagePaging.fulfilled] : (state, action) => {
            state.loading = false
            state.listAllPackagePaging = action.payload
        }
    }
})

export default cooperationPackageSlice