import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import countryApi from "../../api/countryApi";

const getAllCountry = createAsyncThunk('country/getAllCountry', async() => {
    const listCountry = countryApi.getAllCountry()
    return listCountry 
})

const countrySlice = createSlice({
    name: "country",
    initialState: {
        loading: false,
        listCountry: []
    },
    extraReducers: {
        [getAllCountry.pending] : (state, action) => {
            state.loading = true
        },
        [getAllCountry.rejected] : (state, action) => {
            state.loading = false
        },
        [getAllCountry.fulfilled] : (state, action) => {
            state.loading = false
            state.listCountry = action.payload
        },
    }
})

export default countrySlice
export {getAllCountry}