import axiosClient from "./axiosClient";

const customerApi = {
	async getListCustomer() {
		const url = `/customers`;
		return axiosClient.get(url);
	},
	async getCustomerWithPag(index, size) {
		const url = `/customers/all-paging?page_index=${index}&page_size=${size}`;
		return axiosClient.get(url);
	},
	async getDetail(id) {
		const url = `/customers/${id}`;
		return axiosClient.get(url);
	},
	async delete(id) {
		const url = `/customers/${id}`;
		return axiosClient.delete(url);
	},
	async update(id, data) {
		const url = `/customers/${id}`;
		return axiosClient.put(url, data);
	},
	async create(data) {
		const url = `/users/register`;
		return axiosClient.post(url, data);
	},
};

export default customerApi;
