import axiosClient from "./axiosClient";

const voucherApi = {
	async getAll() {
		const url = "/vouchers";
		return axiosClient.get(url);
	},
	async getAllwithPag(index, size) {
		const url = `/vouchers/all-paging?page_index=${index}&page_size=${size}`;
		return axiosClient.get(url);
	},
	async getDetail(id) {
		const url = `/vouchers/${id}`;
		return axiosClient.get(url);
	},
	async createVoucher(data) {
		const url = `/vouchers/`;
		return axiosClient.post(url, data, {
			headers: {
				"content-type": "application/json",
			},
		});
	},
	async updateVoucher(id, data) {
		const url = `/vouchers/${id}`;
		return axiosClient.put(url, data);
	},
	async deleteVoucher(id) {
		const url = `/vouchers/${id}`;
		return axiosClient.delete(url);
	},
};

export default voucherApi;
