import axiosClient from "./axiosClient";
const eventApi = {
	getAll() {
		const url = `/events/depend-on-date`;
		return axiosClient.get(url);
	},
	async getAllwithPag(index, size) {
		const url = `/events/all-paging?page_index=${index}&page_size=${size}`;
		return axiosClient.get(url);
	},
	get(id) {
		const url = `/events/${id}`;
		return axiosClient.get(url);
	},
	create(data) {
		const url = `/events`;
		return axiosClient.post(url, data);
	},
	Edit(edit) {
		const url = `/events/${edit.id}`;
		return axiosClient.put(url, edit);
	},
	Delete(id) {
		const url = `/events/${id}`;
		return axiosClient.delete(url);
	},
};
export default eventApi;
