import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import notificationApi from "../../api/notificationApi";

export const notificationsGetAll = createAsyncThunk(
  "notifications/notificationGetAll",
  async () => {
    const listNotification = await notificationApi.getAll();
    return listNotification;
  }
);
export const notificationsGetAllPaging = createAsyncThunk(
  "notifications/notificationsGetAllPaging",
  async ({ current, size }) => {
    const list = await notificationApi.getForUsingPagination({ current, size });
    console.log(list);
    return list;
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notificationList: [],
    loadingNotification: false,
    notificationListPaging: {
      data: [],
    },
    loadingNotificationPaging: false,
    error: "",
  },
  reducers: {},
  extraReducers: {
    [notificationsGetAll.pending]: (state, action) => {
      state.loadingNotification = true;
    },
    [notificationsGetAll.rejected]: (state, action) => {
      state.loadingNotification = false;
    },
    [notificationsGetAll.fulfilled]: (state, action) => {
      state.loadingNotification = false;
      state.notificationList = action.payload;
    },
    [notificationsGetAllPaging.pending]: (state, action) => {
      state.loadingNotificationPaging = true;
    },
    [notificationsGetAllPaging.rejected]: (state, action) => {
      state.loadingNotificationPaging = false;
    },
    [notificationsGetAllPaging.fulfilled]: (state, action) => {
      state.loadingNotificationPaging = false;
      state.notificationListPaging = action.payload;
    },
  },
});

export default notificationSlice;
