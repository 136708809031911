import React from "react";

const ROLES = {
  admin: 1,
  customer: 2,
  adminEcommerce: 3,
  subAdminEcommerce: 4,
  adminStore: 5,
  subAdminStore: 6,
};

const Report = React.lazy(() => import("../pages/report/index"));
const Orders = React.lazy(() => import("../pages/orders/index"));
const Customers = React.lazy(() => import("../pages/customers/index"));
const News = React.lazy(() => import("../pages/news/News"));
const Vouchers = React.lazy(() => import("../pages/vouchers/index"));
const Ecommerce = React.lazy(() => import("../pages/ecommerce/index"));
const Products = React.lazy(() => import("../pages/product/index"));
const Notifications = React.lazy(() => import("../pages/notification/index"));
const Stores = React.lazy(() => import("../pages/stores/index"));
const Brands = React.lazy(() => import("../pages/brands/index"));
const Groups = React.lazy(() => import("../pages/groups/index"));
const Events = React.lazy(() => import("../pages/events/index"));
const Banners = React.lazy(() => import("../pages/banners/index"));
const Users = React.lazy(() => import("../pages/users/index"));
const Project = React.lazy(() => import("../pages/project/Project"));
const CooperationPackage = React.lazy(() => import("../pages/cooperationPackage/index"))

const routes = [
  {
    path: "/",
    name: "report",
    component: Report,
  },
  {
    path: "/orders/*",
    name: "orders",
    component: Orders,
  },
  {
    path: "/users/*",
    name: "users",
    component: Users,
    roles: [ROLES.admin, ROLES.adminEcommerce],
  },
  {
    path: "/ecommerce/*",
    name: "ecommerce",
    component: Ecommerce,
    roles: [ROLES.admin],
  },
  {
    path: "/news/*",
    name: "news",
    component: News,
    roles: [ROLES.admin, ROLES.adminEcommerce],
  },
  {
    path: "/vouchers/*",
    name: "vouchers",
    component: Vouchers,
    roles: [ROLES.admin, ROLES.adminEcommerce],
  },
  {
    path: "/products/*",
    name: "products",
    component: Products,
  },
  {
    path: "/notifications/*",
    name: "notifications",
    component: Notifications,
    roles: [ROLES.admin, ROLES.adminEcommerce],
  },
  {
    path: "/stores/*",
    name: "stores",
    component: Stores,
    roles: [ROLES.admin, ROLES.adminEcommerce],
  },
  {
    path: "/brands/*",
    name: "brands",
    component: Brands,
  },
  {
    path: "/groups/*",
    name: "groups",
    component: Groups,
    roles: [ROLES.admin, ROLES.adminEcommerce],
  },
  {
    path: "/events/*",
    name: "events",
    component: Events,
    roles: [ROLES.admin, ROLES.adminEcommerce],
  },
  {
    path: "/banners/*",
    name: "banners",
    component: Banners,
    roles: [ROLES.admin, ROLES.adminEcommerce],
  },
  {
    path: "/customers/*",
    name: "customsers",
    component: Customers,
  },
  {
    path: "/project/*",
    name: "project",
    component: Project,
  },
  {
    path: "/cooperationPackage/*",
    name: "cooperationPackage",
    component: CooperationPackage,
  },
];

export { routes };
