import axiosClient from "./axiosClient";
const GroupsApi = {
	getAll() {
		const url = `/teams`;
		return axiosClient.get(url);
	},
	async getAllwithPag(index, size) {
		const url = `/teams/all-paging?page_index=${index}&page_size=${size}`;
		return axiosClient.get(url);
	},
	detail(id) {
		const url = `/teams/${id}`;
		return axiosClient.get(url, {
			headers: {
				ecommerce_id: 3,
			},
		});
	},
	create(data) {
		const url = `/teams`;
		return axiosClient.post(url, data, {
			headers: {
				"content-type": "application/json",
			},
		});
	},
	Edit(id, data) {
		const url = `/teams/${id}`;
		return axiosClient.put(url, data, {
			headers: {
				"content-type": "application/json",
			},
		});
	},
	Delete(id) {
		const url = `/teams/${id}`;
		return axiosClient.delete(url);
	},
};
export default GroupsApi;
