import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

function RequireAuth() {
  const { isAuth } = useSelector((state) => state.userReducer);
  const location = useLocation();
  return isAuth ? (
    //outlet trả về component con khi isAuth trả về giá trị true với component con 
    //trong outlet
    <Outlet />
  ) : (
    <Navigate to="/login/" state={{ from: location }} replace />
  );
}

export default RequireAuth;
