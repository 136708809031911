import axiosClient from "./axiosClient";

const newsApi = {
  async getAll() {
    const url = `/news`;
    return axiosClient.get(url);
  },
  async getAllwithPag(index, size) {
    const url = `/news/all-paging?page_index=${index}&page_size=${size}`;
    return axiosClient.get(url);
  },
  async getById(id) {
    const url = `/news/${id}`;
    return axiosClient.get(url);
  },
  async edit(data) {
    const url = `/news/${data.id}`;
    return axiosClient.put(url, data);
  },
  async create(data) {
    const url = `/news`;
    return axiosClient.post(url, data);
  },
  async delete(id) {
    const url = `/news/${id}`;
    return axiosClient.delete(url);
  },
  async geNewsCate() {
    const url = `/news-cate/`;
    return axiosClient.get(url);
  },
};

export default newsApi;
