import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import bannerApi from "../../api/bannerApi";

export const getAllBanner = createAsyncThunk("getAllBanner/banner", async () => {
	const getAll = await bannerApi.getAll();
	return getAll;
});

export const getAllBannerPaging = createAsyncThunk("getAllBannerPaging/banner", async ({ current, size }) => {
	const list = await bannerApi.getForUsingPagination(current, size);
	return list;
});
export const getBannerType = createAsyncThunk("getBannerType/banner", async () => {
	const res = await bannerApi.getBannerType();
	return res;
});

const bannerSlice = createSlice({
	name: "Banner",
	initialState: {
		listBanner: [],
		listBannerPag: [],
		loading: false,
	},
	reducers: {},
	extraReducers: {
		[getAllBanner.pending]: (state) => {
			state.loading = true;
		},
		[getAllBanner.rejected]: (state) => {
			state.loading = false;
		},
		[getAllBanner.fulfilled]: (state, action) => {
			state.loading = false;
			state.listBanner = action.payload;
		},
		[getAllBannerPaging.pending]: (state) => {
			state.loading = true;
		},
		[getAllBannerPaging.rejected]: (state) => {
			state.loading = false;
		},
		[getAllBannerPaging.fulfilled]: (state, action) => {
			state.loading = false;
			state.listBannerPag = action.payload.data;
			state.total = action.payload.total_items;
		},
		[getBannerType.fulfilled]: (state, action) => {
			state.typeBanner = action.payload;
		},
	},
});

export default bannerSlice;
