import voucherApi from "../../api/voucherApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export const getAllVoucher = createAsyncThunk("getAllVoucher/voucher", async () => {
	const getAll = await voucherApi.getAll();
	return getAll;
});
export const getAllVoucherWithPag = createAsyncThunk("getAllVoucherWithPag/voucher", async ({ index, size }) => {
	const res = await voucherApi.getAllwithPag(index, size);
	return res;
});
export const createVoucher = createAsyncThunk("createVoucher/voucher", async (data) => {
	const create = await voucherApi.createVoucher(data);
	return create;
});

const voucherSlice = createSlice({
	name: "Voucher",
	initialState: {
		listVoucher: [],
		listVoucherPag: [],
		loading: false,
		detailVoucher: {},
	},
	reducers: {
		SetList: (state, action) => {
			state.listVoucher = action.payload;
		},
	},
	extraReducers: {
		[getAllVoucher.pending]: (state) => {
			state.loading = true;
		},
		[getAllVoucher.rejected]: (state) => {
			state.loading = false;
		},
		[getAllVoucher.fulfilled]: (state, action) => {
			state.loading = false;
			state.listVoucher = action.payload;
		},
		[getAllVoucherWithPag.pending]: (state) => {
			state.loading = true;
		},
		[getAllVoucherWithPag.rejected]: (state) => {
			state.loading = false;
		},
		[getAllVoucherWithPag.fulfilled]: (state, action) => {
			state.loading = false;
			state.listVoucherPag = action.payload.data;
			state.total = action.payload.total_items;
		},
	},
});

export default voucherSlice;
