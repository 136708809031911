import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import propertyApi from "../../api/propertyApi";

export const getAllProperty = createAsyncThunk(
  "property/getAllProperty",
  async () => {
    const res = await propertyApi.getAll();
    console.log("propertyList:", res);
    return res;
  }
);

const propertySlice = createSlice({
  name: "property",
  initialState: {
    propertyList: [],
    loadingProperty: false,
    error: "",
  },
  reducers: {},
  extraReducers: {
    [getAllProperty.pending]: (state, action) => {
      state.loadingProperty = true;
    },
    [getAllProperty.rejected]: (state, action) => {
      state.loadingProperty = false;
    },
    [getAllProperty.fulfilled]: (state, action) => {
      state.loadingProperty = false;
      state.propertyList = action.payload;
    },
  },
});

export default propertySlice;
