import axiosClient from "./axiosClient"


const fieldsApi = {
    getAllFields() {
        const url = `/fields`
        return axiosClient.get(url)
    }
}

export default fieldsApi